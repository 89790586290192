import axios from 'axios';
async function getEmpresaConfig() {
  try {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/empresas/config?empresa=' + process.env.VUE_APP_EMPRESA);
    return response.data;
  } catch (error) {
    console.error('Error al obtener la configuración de la empresa:', error);
    return null;
  }
}

// Obtener la configuración según la empresa configurada en .env
const config = await getEmpresaConfig();

export default config;