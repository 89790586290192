import Vue from 'vue';
import config from '@/config/empresas';

// Crear un plugin para acceder a la configuración desde cualquier componente
const empresaConfig = {
  install(Vue) {
    Vue.prototype.$empresaConfig = config;
  }
};

Vue.use(empresaConfig);

export default empresaConfig;