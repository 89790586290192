<template>
  <div>
    <v-dialog
      v-model="isPasswordDialog"
      max-width="450px"
      persistent
    >
      <v-card class="password-dialog">
        <v-card-text class="text-center pt-8 pb-0">
          <v-avatar
            color="primary"
            size="64"
            class="mb-4"
          >
            <v-icon size="36" color="white">mdi-shield-lock-outline</v-icon>
          </v-avatar>
          
          <h2 class="text-h5 font-weight-medium mb-2">
            Cambiar Contraseña
          </h2>
          <p class="text-body-2 text-medium-emphasis mb-6">
            Por tu seguridad, ingresa una contraseña segura
          </p>
        </v-card-text>

        <v-card-text class="pt-0 pb-4">
          <v-form ref="resetPass" v-model="valid" lazy-validation>
            <!-- Campo para contraseña actual -->
            <div class="password-field mb-4">
              <v-text-field
                v-model="passwordData.oldPassword"
                :type="inputType(0)"
                label="Contraseña Actual"
                :append-icon="viewPassIcon(0)"
                @click:append="togglePasswordVisibility(0)"
                dense
                outlined
                placeholder="Ingresa tu contraseña actual"
                class="password-input"
                :rules="currentPasswordRules"
                autocomplete="current-password"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary" class="mr-2">mdi-lock-outline</v-icon>
                </template>
              </v-text-field>
            </div>

            <!-- Campo para nueva contraseña -->
            <div class="password-field mb-4">
              <v-text-field
                v-model="passwordData.newPassword"
                :type="inputType(1)"
                label="Nueva Contraseña"
                :append-icon="viewPassIcon(1)"
                @click:append="togglePasswordVisibility(1)"
                dense
                outlined
                placeholder="Ingresa tu nueva contraseña"
                class="password-input"
                :rules="passwordRules"
                autocomplete="new-password"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary" class="mr-2">mdi-key-outline</v-icon>
                </template>
              </v-text-field>
              
              <div class="password-strength mt-1" v-if="passwordData.newPassword">
                <v-chip
                  x-small
                  :color="passwordStrength.color"
                  text-color="white"
                  class="mr-1"
                >
                  {{ passwordStrength.text }}
                </v-chip>
              </div>
            </div>

            <!-- Campo para confirmar contraseña -->
            <div class="password-field">
              <v-text-field
                v-model="passwordData.confirmPassword"
                :type="inputType(2)"
                label="Confirmar Contraseña"
                :append-icon="viewPassIcon(2)"
                @click:append="togglePasswordVisibility(2)"
                dense
                outlined
                placeholder="Confirma tu nueva contraseña"
                :rules="repeatPasswordRules"
                autocomplete="new-password"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary" class="mr-2">mdi-shield-check-outline</v-icon>
                </template>
              </v-text-field>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-btn
            text
            @click="closeDialog"
            :disabled="loading"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="isSubmitDisabled"
            class="px-6"
            @click="enviarPass"
          >
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DialogoCambioPass",
  props: {
    isPasswordDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      apiEndpoint: "usuarios/auth/resetpass",
      viewPass: [false, false, false],
      passwordData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        id_usuario: null
      },
      currentPasswordRules: [
        v => !!v || 'La contraseña actual es requerida'
      ],
      passwordRules: [
        v => !!v || 'La contraseña es requerida',
        v => (v && v.length >= 8) || 'Mínimo 8 caracteres',
        v => /[A-Z]/.test(v) || 'Debe contener al menos una mayúscula',
        v => /[0-9]/.test(v) || 'Debe contener al menos un número'
      ],
      repeatPasswordRules: [
        v => !!v || 'Confirma tu contraseña',
        v => v === this.passwordData.newPassword || 'Las contraseñas no coinciden'
      ]
    };
  },
  computed: {
    passwordStrength() {
      const pass = this.passwordData.newPassword;
      if (!pass) return { text: '', color: '' };
      
      const hasUpperCase = /[A-Z]/.test(pass);
      const hasLowerCase = /[a-z]/.test(pass);
      const hasNumbers = /\d/.test(pass);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
      
      const strength = [hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChar].filter(Boolean).length;
      
      if (pass.length < 8) return { text: 'Débil', color: 'error' };
      if (strength <= 2) return { text: 'Media', color: 'warning' };
      if (strength === 3) return { text: 'Fuerte', color: 'success' };
      return { text: 'Muy Fuerte', color: 'success' };
    },
    isSubmitDisabled() {
      const { oldPassword, newPassword, confirmPassword } = this.passwordData;
      return !oldPassword || !newPassword || !confirmPassword || !this.valid;
    },
    inputType() {
      return (index) => (this.viewPass[index] ? "text" : "password");
    },
    viewPassIcon() {
      return (index) => (this.viewPass[index] ? "mdi-eye-off" : "mdi-eye");
    },
  },
  
  methods: {
    

    /**
     * Cierra el diálogo y resetea el formulario
     */
    closeDialog() {
      this.resetForm();
      this.$emit("cerrar-cambio-pass", false);
    },

    /**
     * Resetea el formulario y los datos
     */
    resetForm() {
      if (this.$refs.resetPass) {
        this.$refs.resetPass.reset();
      }
      this.passwordData.oldPassword = "";
      this.passwordData.newPassword = "";
      this.passwordData.confirmPassword = "";
      this.valid = false;
    },

    /**
     * Envía la solicitud de cambio de contraseña al servidor
     */
    async enviarPass() {
      if (!this.$refs.resetPass.validate()) {
        this.showToast('Las contraseñas no coinciden o no cumplen con los requisitos', 'error');
        return;
      }
     let  userData = JSON.parse(localStorage.getItem("token"))
      const payload = {
        id_usuario: userData.id_usuario,
        oldPassword: this.passwordData.oldPassword,
        newPassword: this.passwordData.newPassword
      };

      try {
        this.loading = true;
        const response = await this.sendPasswordChangeRequest(payload);
        this.handleSuccessResponse(response);
      } catch (error) {
        this.handleErrorResponse(error);
      } finally {
        this.loading = false;
        this.resetForm();
        this.$emit("cerrar-cambio-pass", false);
      }
    },

    /**
     * Envía la solicitud HTTP al servidor
     * @param {Object} payload - Datos a enviar
     * @returns {Promise} - Promesa con la respuesta
     */
    async sendPasswordChangeRequest(payload) {
      return await axios.post(
        `${process.env.VUE_APP_BASE_URL}${this.apiEndpoint}`,
        payload
      );
    },

    /**
     * Maneja la respuesta exitosa del servidor
     * @param {Object} response - Respuesta del servidor
     */
    handleSuccessResponse(response) {
      const { data } = response;
      this.showToast(data.msg, data.type || 'success');
    },

    /**
     * Maneja los errores de la solicitud
     * @param {Error} error - Error capturado
     */
    handleErrorResponse(error) {
      console.error('Error al cambiar contraseña:', error);
      
      let message = 'Error al cambiar la contraseña. Por favor, comunícate con soporte.';
      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      
      this.showToast(message, 'error');
    },

    /**
     * Muestra un mensaje toast
     * @param {string} message - Mensaje a mostrar
     * @param {string} type - Tipo de mensaje (success, error, info, etc)
     * @param {number} duration - Duración en milisegundos
     */
    showToast(message, type = 'info', duration = 4000) {
      this.$toast.open({
        message,
        position: 'top-right',
        type,
        duration,
      });
    },

    /**
     * Alterna la visibilidad de la contraseña
     * @param {number} index - Índice del campo de contraseña
     */
    togglePasswordVisibility(index) {
      this.$set(this.viewPass, index, !this.viewPass[index]);
    },
  },
};
</script>

<style scoped>
.password-dialog {
  border-radius: 8px;
}

.password-field {
  position: relative;
}

.password-strength {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.v-card-actions {
  background-color: #f5f5f5;
}
</style>
