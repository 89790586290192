
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import config from '@/config/empresas';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true }, 
    themes: {
      light: config.theme,
    },
  },
});