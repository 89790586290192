import axios from "axios";

const state = () => ({
  productos: [],
  productoEditar:null,
  catalogosBancos:[],
  bancos:[],
  tiposTarjetas:[],
  loderProducts: false,
  
  listasPrecio: [],
  listaSeleccionada: null,
});
const getters = {
  tiposTarjetasState(state) {
    return state.tiposTarjetas;
  },
  bancosState(state) {
    return state.bancos;
  },
  productosState(state) {
    return state.productos;
  },
  loderTableProductsState(state) {
    return state.loderProducts;
  },
  listasPrecioState(state) {
    return state.listasPrecio;
  },
  listaSeleccionadaState(state) {
    return state.listaSeleccionada;
  },
  getProductoSeleccionado(state){
    return state.productoEditar
  }
};
const mutations = {
  setProductoSeleccionado(state,prductoSeleccionado){
    state.productoEditar = prductoSeleccionado
  },
  getProductosLoad(state, payload) {
    state.productos = payload;
    state.loderProducts = false;
  },
  getCatalogosLoad(state, payload) {
    state.catalogosBancos = payload;
    state.bancos= payload.bancos
    state.tiposTarjetas= payload.mediosPago
    console.log('los bancos',state.bancos);
    console.log('los mediosPagos',state.tiposTarjetas);
    
   
  },
  setListasPrecio(state, payload) {
    state.listasPrecio = payload;
  },
  setListaSeleccionada(state, payload) {
    state.listaSeleccionada = payload;
  },
  setLoaderProducts(state, payload) {
    state.loderProducts = payload;
  },
  clearProductos(state) {
    state.productos = [];
  }
};
const actions = {
  seleccionarProducto({commit},payload){
    console.log('entra en la accion');
    
    commit("setProductoSeleccionado",payload)

  },
  async getProductos({ commit, state, dispatch }, refresh) {
    try {
      // Asegurarse de que haya una lista de precios seleccionada
      if (!state.listaSeleccionada) {
        await dispatch('getListasPrecio');
      }

      // Solo recargar productos si no hay o si se solicita refresh
      if (state.productos.length === 0 || refresh) {
        commit("setLoaderProducts", true);
        commit("clearProductos"); // Limpiamos los productos antes de cargar nuevos
        
        const resp = await axios.get(
          `${process.env.VUE_APP_BASE_URL}productos?id_lista=${state.listaSeleccionada}`
        );
        console.log("entro en el get productos", resp.data);
        commit("getProductosLoad", resp.data.data);
      }
    } catch (error) {
      console.error("Error al obtener productos:", error);
      commit("getProductosLoad", []);
      commit("setLoaderProducts", false);
    }
  },
  async getListasPrecio({ commit, state }) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_BASE_URL + "productos/listas-precios"
      );
      console.log("listas de precios", response.data);
      
      commit("setListasPrecio", response.data);
      
      // Establecer lista predeterminada si existe
      const listaPredeterminada = response.data.find(
        (lista) => lista.predeterminada
      );
      if (listaPredeterminada) {
        commit("setListaSeleccionada", listaPredeterminada.id_lista);
      }
      
      return response.data;
    } catch (error) {
      console.error("Error al obtener listas de precios:", error);
      return [];
    }
  },
  async actualizarProductosPorLista({ commit, state }, params) {
    try {
      console.log('Actualizando lista de precios:', params.idLista);
      console.log('Lista anterior:', state.listaSeleccionada);
      
      commit("setLoaderProducts", true);
      commit("clearProductos");
      commit("setListaSeleccionada", params.idLista);
      
      console.log('Nueva lista seleccionada:', state.listaSeleccionada);
      console.log("idLista",params.idLista);
      console.log('venta',params.venta);
      
      const url = params.venta 
      ? `${process.env.VUE_APP_BASE_URL}productos/combos?id_lista=${params.idLista}` 
      :`${process.env.VUE_APP_BASE_URL}productos?id_lista=${params.idLista}` ;
    
    const resp = await axios.get(url);
      console.log("Respuesta del API:", resp.data);
      
      if (resp.data && resp.data.data) {
        commit("getProductosLoad", resp.data.data);
      } else {
        console.error("Formato de respuesta inválido:", resp.data);
        commit("getProductosLoad", []);
      }
    } catch (error) {
      console.error("Error al actualizar productos por lista:", error);
      commit("getProductosLoad", []);
      commit("setLoaderProducts", false);
    }
  },
  getCatalogosBancos({ commit, state }, refresh) {
    return new Promise(async (resolve) => {
      try {
       
        if (state.catalogosBancos.length !== 0 && !refresh) {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.catalogosBancos = [];
        
        console.log('>>>>>',state.catalogosBancos);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "cajas/catalogos");
        console.log("entro en el get catalogos",resp.data);
        commit("getCatalogosLoad", resp.data);
        resolve();
      } catch (e) {
        console.log("erroorrrr",e);
      }
    });
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
