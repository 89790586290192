import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../views/Inicio.vue";
import Denegado from "../views/Denegado.vue";
import errorPage from "../views/ErrorPage.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
    meta: {
      rutaName: "inicio",
    },
  },
  {
    path: "/404",
    name: "Denegado",
    component: Denegado,
  },
  {
    path: "*",
    name: "ErrorPage",
    component: errorPage,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import("../views/Usuarios.vue"),
    meta: {
      rutaName: "usuarios",
    },
  },
  {
    path: "/usuarios/add",
    name: "UsuariosAdd",
    component: () => import("../views/usuarios/UserAdd.vue"),
    meta: {
      rutaName: "usuarios/add",
    },
  },
  {
    path: "/productos",
    name: "Productos",
    component: () => import("../views/productos/Productos.vue"),
    meta: {
      rutaName: "productos",
    },
  },
  {
    path: "/radar",
    name: "Radar",
    component: () => import("../views/Radar/Radar.vue"),
    meta: {
      rutaName: "radar",
    },
  },
  {
    path: "/productos/add",
    name: "ProductosAdd",
    component: () => import("../views/productos/ProductosAdd.vue"),
    meta: {
      rutaName: "productos/add",
    },
  },
  {
    path: "/productos/edit/:id",
    name: "ProductosEdit",
    component: () => import("../views/productos/ProductosEdit.vue"),
    meta: {
      rutaName: "productos/edit",
    },
  },
  {
    path: "/parametros",
    name: "Parametros",
    component: () => import("../views/Parametros.vue"),
    meta: {
      rutaName: "parametros",
    },
  },
  {
    path: "/parametros/:tipo",
    name: "ParametrosTable",
    component: () => import("../views/ParametrosTable.vue"),
    meta: {
      rutaName: "parametros/",
    },
  },
  {
    path: "/marcas/add",
    name: "marcas",
    component: () => import("../views/marcas/MarcasAdd.vue"),
    meta: {
      rutaName: "marcas/add",
    },
  },
  {
    path: "/marcas/edit/:id",
    name: "MarcasEdit",
    component: () => import("../views/marcas/MarcasEdit.vue"),
    meta: {
      rutaName: "marcas/edit",
    },
  },
  {
    path: "/categorias",
    name: "Categorias",
    component: () => import("../views/categorias/Categorias.vue"),
    meta: {
      rutaName: "categorias",
    },
  },
  {
    path: "/categoria/add",
    name: "categoriasAdd",
    component: () => import("../views/categorias/CategoriasAdd.vue"),
    meta: {
      rutaName: "categoria/add",
    },
  },
  {
    path: "/categoria/edit/:id",
    name: "CategoriaEdit",
    component: () => import("../views/categorias/CategoriasEdit.vue"),
    meta: {
      rutaName: "categoria/edit",
    },
  },
  {
    path: "/subcategoria",
    name: "SubCategorias",
    component: () => import("../views/subcategorias/SubCategorias.vue"),
    meta: {
      rutaName: "subcategoria",
    },
  },
  {
    path: "/subcategorias/add",
    name: "subcategoriasAdd",
    component: () => import("../views/subcategorias/SubCategoriasAdd.vue"),
    meta: {
      rutaName: "subcategorias/add",
    },
  },
  {
    path: "/subcategoria/edit/:id",
    name: "SubCategoriaEdit",
    component: () => import("../views/subcategorias/SubcategoriaEdit.vue"),
    meta: {
      rutaName: "subcategoria/edit",
    },
  },
  {
    path: "/proveedores",
    name: "Proveedores",
    component: () => import("../views/proveedores/Proveedores.vue"),
    meta: {
      rutaName: "proveedores",
    },
  },
  {
    path: "/proveedores/add",
    name: "proveedoresAdd",
    component: () => import("../views/proveedores/ProveedoresAdd.vue"),
    meta: {
      rutaName: "proveedores/add",
    },
  },
  {
    path: "/proveedores/edit/:id",
    name: "ProveedoresEdit",
    component: () => import("../views/proveedores/ProveedoresEdit.vue"),
    meta: {
      rutaName: "proveedores/edit",
    },
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import("../views/clientes/Clientes.vue"),
    meta: {
      rutaName: "clientes",
    },
  },
  {
    path: "/clientes/add",
    name: "clientesAdd",
    component: () => import("../views/clientes/ClientesAdd.vue"),
    meta: {
      rutaName: "clientes/add",
    },
  },
  {
    path: "/clientes/edit/:id",
    name: "ClientesEdit",
    component: () => import("../views/clientes/ClientesEdit.vue"),
    meta: {
      rutaName: "clientes/edit",
    },
  },
  {
    path: "/ventas",
    name: "ventas",
    component: () => import("../views/ventas/Ventas.vue"),
    meta: {
      rutaName: "ventas",
    },
  },
  {
    path: "/admin-ventas",
    name: "admin-ventas",
    component: () => import("../views/admin-ventas/AdminVentas.vue"),
    meta: {
      rutaName: "admin-ventas",
    },
  },
  {
    path: "/admin-ventas/edit/:id",
    name: "admin-ventas-edit",
    component: () => import("../views/admin-ventas/AdminVentasEdit.vue"),
    meta: {
      rutaName: "admin-ventas/edit",
    },
  },
  {
    path: "/deposito",
    name: "deposito",
    component: () => import("../views/deposito/Deposito.vue"),
    meta: {
      rutaName: "deposito",
    },
  },
  {
    path: "/deposito/edit/:id",
    name: "deposito-edit",
    component: () => import("../views/deposito/AdminVentasEditDeposito.vue"),
    meta: {
      rutaName: "deposito/edit",
    },
  },
  {
    path: "/estadisticas",
    name: "estadisticas",
    component: () => import("../views/estadisticas/Estadisticas.vue"),
    meta: {
      rutaName: "estadisticas",
    },
  },
  {
    path: "/reportes",
    name: "reportes",
    component: () => import("../views/estadisticas/Reportes.vue"),
    meta: {
      rutaName: "reportes",
    },
  },
  {
    path: "/pagos",
    name: "pagos",
    component: () => import("../views/estadisticas/Pagos.vue"),
    meta: {
      rutaName: "pagos",
    },
  },
  {
    path: "/egresos",
    name: "egresos",
    component: () => import("../views/Egresos.vue"),
    meta: {
      rutaName: "egresos",
    },
  },
  {
    path: "/productos/actualizar_stock",
    name: "actualizar_stock",
    component: () => import("../views/productos/ActualizaStockView.vue"),
    meta: {
      rutaName: "productos/actualizar_stock",
    },
  },
  {
    path: "/compras",
    name: "compras",
    component: () => import("../views/compras/ComprasView.vue"),
    meta: {
      rutaName: "compras",
    },
  },
  {
    path: "/productos/ofertas",
    name: "Ofertas",
    component: () => import("../views/ofertas/Ofertas.vue"),
    meta: {
      rutaName: "productos/ofertas",
    },
  },
  {
    path: "/productos/ofertas/add-edit/:id",
    name: "OfertasAdmin",
    component: () => import("../views/ofertas/OfertasAdmin.vue"),
    meta: {
      rutaName: "productos/ofertas/add-edit",
    },
  },
  {
    path: "/listas-precios",
    name: "ListasPrecios",
    component: () => import("../views/listas-precios/ListasPrecios.vue"),
    meta: {
      rutaName: "listas-precios",
    },
  },
  {
    path: "/listas-precios/add",
    name: "ListasPreciosAdd",
    component: () => import("../views/listas-precios/ListasPreciosAdd.vue"),
    meta: {
      rutaName: "listas-precios/add",
    },
  },
  {
    path: "/listas-precios/edit/:id",
    name: "ListasPreciosEdit",
    component: () => import("../views/listas-precios/ListasPreciosEdit.vue"),
    meta: {
      rutaName: "listas-precios/edit",
    },
  },
  {
    path: "/pos",
    name: "pos",
    component: () => import("../views/pos/Pos.vue"),
    meta: {
      rutaName: "pos",
    },
  },
  {
    path: "/nota-credito",
    name: "notaCredito",
    component: () => import("../views/pos/NotaCredito.vue"),
    meta: {
      rutaName: "nota-credito",
    },
  },
  {
    path: "/pos/caja/:tipo/:id",
    name: "cierreCaja",
    component: () => import("../views/pos/cierreCaja.vue"),
    meta: {
      rutaName: "pos/caja/cierre",
    },
  },{
    path: "/cajas",
    name: "cajas",
    component: () => import("../views/pos/Cajas.vue"),
    meta: {
      rutaName: "cajas",
    },
  },
  {
    path: "/promo-redes",
    name: "promo-redes",
    component: () => import("../views/promos/AdminPromos.vue"),
    meta: {
      rutaName: "promo-redes",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  let user = JSON.parse(localStorage.getItem("token")); 
  
  if (!user && to.name !== "Login") {
    next({ name: "Login" });
    return;
  }

  
  if (!to.matched.some((record) => record.meta.rutaName)) {
    next();
    return;
  }

 
  const rutas = to.matched[0].meta.rutaName;
  if (!user || typeof user.id_rol === "undefined") {
    console.log("Usuario no definido, redirigiendo al Login");
    next({ name: "Login" });
  } else if (typeof user.rutas[rutas] === "undefined") {
    console.log("Ruta no permitida");
    next({ name: "Denegado" });
  } else if (user.rutas[rutas] === 1) {
    next();
  } else {
    next({ name: "Denegado" });
  }
});

export default router;
